import { render, staticRenderFns } from "./OccupationAreaForm.vue?vue&type=template&id=167d2506&scoped=true&"
import script from "./OccupationAreaForm.vue?vue&type=script&lang=js&"
export * from "./OccupationAreaForm.vue?vue&type=script&lang=js&"
import style0 from "./OccupationAreaForm.vue?vue&type=style&index=0&id=167d2506&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167d2506",
  null
  
)

export default component.exports