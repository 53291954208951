const occupationAreasMapper = (occupationAreas) =>
  occupationAreas.map((occupationArea) =>
    `${occupationArea.occupationArea} - RQE: ${occupationArea.occupationAreaRqe} - ${occupationArea.publishMedicalGuide ? "Divulgar" : "Não divulgar"} no Guia Médico`)?.join(", ");

export default function mapperToOccupationAreaRequest(
  formData,
  requestAction
) {
  let occupationAreaRequest =  {
    "Ação da solicitação": requestAction,
    Especialidade: formData.specialtyDescription,
    "Divulgar Guia Médico": formData.publishMedicalGuide ? "Sim" : "Não",
    "Especialidade Principal": formData.mainSpecialtyMedical,
    "Especialidade RQE": formData.specialtyRqe,
    "Áreas de atuação": occupationAreasMapper(formData.occupationAreas),    
  };

  return occupationAreaRequest;
}