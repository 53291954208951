<template>
  <div>
    <div
      v-show="isMobile()"
      class="alter-form"
      v-for="(occupation, index) in $props.occupations"
      :key="index"
    >
      <div class="form-flex-column">
        <div class="col">
          ÁREA DE ATUAÇÃO
        </div>
        <div class="col">
          <b class="text-align-left">{{ occupation.occupationArea }}</b>
        </div>
      </div>
      <div class="form-flex-column">
        <div class="col">
          RQE
        </div>
        <div class="col">
          <b class="text-align-left">{{ occupation.occupationAreaRqe }}</b>
        </div>
      </div>
      <div class="form-flex-column">
        <div class="col">
          DIVULGAR NO GUIA MÉDICO?
        </div>
        <div class="col">
          <b>{{ occupation.publishMedicalGuide }}</b>
        </div>
      </div>
    </div>
    <b-table
      v-show="!isMobile()"
      class="occupation spaced-text"
      :items="$props.occupations"
      :fields="tableFields"
      stacked
    >
    </b-table>
  </div>
</template>

<script>
const tableFields = [
  {
    key: "occupationArea",
    label: "ÁREA DE ATUAÇÃO",
    tdClass: "text-right",
    thStyle: {
      display: "none",
    },
  },
  {
    key: "occupationAreaRqe",
    label: "RQE",
    tdClass: "text-right",
    thStyle: {
      display: "none",
    },
  },
  {
    key: "publishMedicalGuide",
    label: "DIVULGAR NO GUIA MÉDICO?",
    tdClass: "text-right",
    thStyle: {
      display: "none",
    },
  },
];
import { isMobile } from "../../../../../utils/mobile/mobile";

export default {
  props: ["occupations"],
  components: {},
  methods: {
    isMobile: isMobile,
  },
  data() {
    return {
      tableFields: tableFields,
    };
  },
};
</script>

<style lang="scss">
.occupation.table td {
  border: none;
  padding: 0;
}
.occupation.table tr {
  padding: 1.5rem 3rem;
  border-top: 1px solid var(--light-0);
}

.occupation.table.b-table.b-table-stacked {
  & :first-child {
    border: none;
    border-color: transparent;
  }
}
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
</style>
