<template>
  <div>
    <b-row class="justify-content-center my-4">
      <p class="p4">
        Confira seus dados e, caso haja divergências, solicite alteração
        cadastral a qualquer momento.
      </p>
    </b-row>

    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="specialtyDescription"
        :items="this.tableItems"
        :itemsFields="this.tableFields"
        :iconName="this.iconName"
        :getCustomTable="this.getCustomTable"
      />

      <OccupationAreaAlterModal
        :loadedFormDataOptions="this.tableItems"
        :medicalAreaOptions="this.medicalAreaOptions"
      />

      <div>
        <div class="mt-5">
          <b-button
            class="m-3"
            variant="primary"
            v-b-modal:alter-occupation-area-modal
            v-if="this.tableItems && this.tableItems.length > 0"
          >
            Alterar área de atuação
          </b-button>
        </div>
      </div>
    </div>

    <div v-show="this.loading">
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
      <p />
      <b-card>
        <b-skeleton type="AccordionList" width="100%"></b-skeleton>
        <b-skeleton-table
          :rows="tableFields.length"
          :columns="2"
          :hide-header="true"
        ></b-skeleton-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { getOccupationAreas } from "../../../../services/occupation-area/occupation-area-services";
import { getMedicalOperationAreas } from "../../../../services/medical-operation-area/medical-operation-area-services";
import AccordionList from "../../../../components/accordion-list/AccordionList.vue";
import OccupationAreaAlterModal from "../forms/occupation/OccupationAreaAlterModal.vue";
import OccupationAreaTable from "../forms/occupation/OccupationAreaTable.vue";
import { isMobile } from "../../../../utils/mobile/mobile";

Vue.component("occupation-area-table", () =>
  import("../forms/occupation/OccupationAreaTable.vue")
);

export default {
  name: "occupation-area",
  components: {
    AccordionList,
    OccupationAreaAlterModal,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;
    this.getOccupationAreas(crm);
    this.getMedicalAreaOptions();
  },
  data() {
    return {
      tableFields: [
        {
          key: "specialtyDescription",
          label: "Especialidade",
          tdClass: "text-right",
        },
        {
          key: "areaDescription",
          label: "Área de Atuação",
          tdClass: "text-right",
        },
        {
          key: "publishMedicalGuide",
          label: "Divulgar Guia Médico",
          tdClass: "text-right",
        },
        {
          key: "mainSpecialtyMedical",
          label: "Especialidade Principal",
          tdClass: "text-right",
        },
      ],
      tableItems: [],
      medicalAreaOptions: [],
      loading: true,
      iconName: [
        { icon: "iconMedicalGuide", tooltip: "Divulgar Guia Médico" },
        { icon: "iconSpecialtyMedical", tooltip: "Especialidade Principal" },
      ],
    };
  },
  methods: {
    isMobile: isMobile,
    async getOccupationAreas(crm) {
      try {
        this.loading = true;
        const occupation = await getOccupationAreas(crm);
        this.loading = false;

        this.tableItems = occupation.map((me) => {
          me.occupations = me.occupations.map((oc) => {
            oc.publishMedicalGuide =
              oc.publishMedicalGuide.toLowerCase() == "s" ? "Sim" : "Não";
            return oc;
          });
          me.mainSpecialtyMedical =
            me.mainSpecialtyMedical.toLowerCase() == "s" ? "Sim" : "Não";
          me.iconSpecialtyMedical =
            me.mainSpecialtyMedical.toLowerCase() == "sim"
              ? "heart-pulse.svg"
              : null;

          return me;
        });
      } catch (error) {
        this.loading = false;
        console.error("getOccupationAreas", error);
      }
    },
    async getMedicalAreaOptions() {
      try {
        this.loading = true;

        const medicalAreaOptions = await getMedicalOperationAreas();

        this.loading = false;
        this.medicalAreaOptions = medicalAreaOptions;
      } catch (error) {
        this.loading = false;
        console.error("getMedicalSpecialities", error);
      }
    },
    getCustomTable(item) {
      return <OccupationAreaTable occupations={item.occupations} />;
    },
  },
};
</script>
