import TypeRequestedFlow from "../../static-data/TypeRequestedFlow";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";
import axios from "../http-client/axios";
import requestBuilder from "../request-builder/request-builder";
import { METALICA_URL, SCORPIONS_URL } from "../urls/base-urls";
import mapperToOccupationAreaRequest from "./occupation-area-mapper";

export async function getOccupationAreas(crm) {
  
  const resp = await axios({
    url: `${SCORPIONS_URL}/occupation-area?crm=${crm}`,
    method: "get",
    data: {},
  });
  return resp.data;

}

export async function updateOccupationArea(formData, attachments) {
  var originalData = mapperToOccupationAreaRequest(
    formData.originalData,
    FormRequestAction.update
  );

  var changedData = mapperToOccupationAreaRequest(
    formData,
    FormRequestAction.update
  );

  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      originalData,
      TypeRequestedFlow.update,
      attachments,
      ServiceRequestType.DOCTOR_MEDICAL_SPECIALTIES_OCCUPATION_AREAS,
      null,
      changedData
    )
  );
  return resp.data;
}

export async function getOccupationAreasNotification(crm) {
  
  const resp = await axios({
    url: `${SCORPIONS_URL}/occupation-area?crm=${crm}`,
    method: "get",
    data: {},
  });
  return resp.data;

}

export async function updateOccupationAreaPublicizeOption(request) {
  
  const resp = await axios({
    url: `${SCORPIONS_URL}/notification/occupation-area`,
    method: "put",
    data: request,
  });
  return resp.data;

}
