<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: São permitidas no máximo 02 áreas de atuação ativas por
      especialidade.
    </p>
    <b-card v-if="!$props.newRegister" class="custom-card my-4">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>
    <b-card class="custom-card my-4" v-show="showForm()" border-variant="light">
      <div v-for="(occupation, index) in form.occupationAreas" :key="index">
        <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
          <div class="col">
            Área de atuação
          </div>
          <div class="col">
            <b-form-select
              :class="isMobile() ? 'text-align-left' : ''"
              v-model="occupation.occupationArea"
              :state="validateFieldOccupationArea(occupation)"
              :options="areaOptions"
            />
            <div md="2">
              <img
                class="icon-remove"
                src="@/assets/icons/close-circle.svg"
                @click="removeOccupationArea(occupation)"
              />
              <img
                v-show="form.occupationAreas.length == index + 1"
                class="icon-heart-plus"
                src="@/assets/icons/heart-plus.svg"
                @click="newOccupationArea(occupation)"
              />
            </div>
          </div>
        </div>
        <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
          <div class="col">
            RQE
          </div>
          <div class="col">
            <b-form-input
              v-model.lazy.trim="occupation.occupationAreaRqe"
              type="number"
              :state="validateRQE(index, 'occupationAreaRqe')"
            />
          </div>
        </div>
        <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
          <div class="col">
            Divulgar Guia Médico
          </div>
          <div class="col">
            <b-form-checkbox
              :class="isMobile() ? 'check-apadding text-align-left' : 'ml-auto'"
              switch
              v-model="occupation.publishMedicalGuide"
            >
              {{ occupation.publishMedicalGuide ? "Sim" : "Não" }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <b-form-row>
        <div class="invalid" v-show="occupationAreasActiveInvalidQuantity">
          <i class="fas fa-exclamation-circle invalid"></i>
          São permitidas no máximo 02 áreas de atuação ativas por especialidade.
        </div>
      </b-form-row>
      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>
<script>
import { inject } from "@vue/composition-api";
import UploadFile from "../../../../../components/upload-file/UploadFile.vue";
import { isMobile } from "../../../../../utils/mobile/mobile";

const DefaultForm = {
  specialtyDescription: null,
  areaDescription: null,
  publishMedicalGuide: false,
  mainSpecialtyMedical: false,
  files: null,
  specialty: null,
  occupationAreas: [
    {
      occupationArea: "",
      publishMedicalGuide: false,
      occupationAreaValid: false,
      rqeValid: false,
    },
  ],
  originalData: null,
};
export default {
  components: { UploadFile },
  props: ["medicalAreaOptions", "loadedFormDataOptions", "newRegister"],
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      occupationAreas: [],
      areaOptions: [],
      selectedOption: null,
      selectOptions: [],
      occupationAreasActiveInvalidQuantity: false,
      originalData: null,
    };
  },
  methods: {
    validateRQE(index) {
      let value = this.form.occupationAreas[index].occupationAreaRqe;
      let validation = !!value;
      this.form.occupationAreas[index].rqeValid = validation;
      if (!this.formSubmited) return null;
      return !!value;
    },
    isMobile: isMobile,
    initForm() {
      this.setSubmitHandler(this.onSubmit);

      const loadedFormDataOptions = this.$props.loadedFormDataOptions;
      this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      this.areaOptions = this.buildMedicalAreaOptions(
        this.$props.medicalAreaOptions
      );

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: false,
      };
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    buildSelectOptions(formDataOptions) {
      return [
        { value: null, text: "selecione uma especialidade" },
        ...formDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.specialtyDescription,
        })),
      ];
    },
    buildMedicalAreaOptions(medicalAreaOptions) {
      return [
        { value: null, text: "Selecione uma área de atuação", disabled: true },
        ...medicalAreaOptions.map((areaOptions) => ({
          value: areaOptions.description,
          text: areaOptions.description,
        })),
      ];
    },
    buildOccupationAreas(occupations) {
      return occupations.map((occupation) => {
        return {
          occupationArea: occupation.occupationArea.trim(),
          publishMedicalGuide:
            occupation.publishMedicalGuide &&
            occupation.publishMedicalGuide.toLowerCase() == "sim",
          occupationAreaValid: true,
          rqeValid: true,
          occupationAreaRqe: occupation.occupationAreaRqe.trim(),
        };
      });
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm, specialty: this.selectedOption.text };
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          onDeleteHandler: this.onDelete,
          formSelected: false,
        };
        return;
      }
      let occupationAreas = this.buildOccupationAreas(formData.occupations);
      this.form.occupationAreas = occupationAreas;
      const form = {
        ...this.form,
        ...formData,
      };
      this.form = form;
      this.form.originalData = this.setInitialDados(this.form);

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: true,
      };
    },
    validateFieldFile(name) {
      let value = this.form[name];
      const validation = !!value && value.length > 0;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateFieldOccupationArea(occupationArea) {
      let validation = true;
      validation = !!occupationArea.occupationArea;
      occupationArea.occupationAreaValid = validation;
      if (!this.formSubmited) return null;
      return validation;
    },
    validateOccupationAreasActiveQuantity() {
      this.occupationAreasActiveInvalidQuantity =
        this.form.occupationAreas.filter(
          (occupationArea) => occupationArea.publishMedicalGuide
        ).length > 2;
    },
    requiredFieldsFilled() {
      return (
        Object.values(this.form.occupationAreas).every(
          (occupationArea) =>
            occupationArea.occupationAreaValid && occupationArea.rqeValid
        ) &&
        Object.values(this.validations).every(
          (fieldValue) => fieldValue == true
        )
      );
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;

      this.validateOccupationAreasActiveQuantity();

      if (this.occupationAreasActiveInvalidQuantity) {
        return false;
      }
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    newOccupationArea(occupationArea) {
      this.validateFieldOccupationArea(occupationArea);

      this.form.occupationAreas.push({
        occupationArea: "",
        publishMedicalGuide: false,
      });
    },
    removeOccupationArea(occupationArea) {
      if (this.form.occupationAreas.length > 1)
        this.form.occupationAreas.splice(
          this.form.occupationAreas.indexOf(occupationArea),
          1
        );
    },
    setInitialDados(value) {
      const occupationAreasMapper = (occupationAreas) =>
        occupationAreas.map((field) => ({
          occupationArea: field.occupationArea,
          publishMedicalGuide: field.publishMedicalGuide,
          occupationAreaValid: field.occupationAreaValid,
          rqeValid: field.rqeValid,
          occupationAreaRqe: field.occupationAreaRqe,
        }));

      return {
        specialtyDescription: value.specialtyDescription,
        areaDescription: value.areaDescription,
        publishMedicalGuide: value.publishMedicalGuide,
        mainSpecialtyMedical: value.mainSpecialtyMedical,
        files: value.files,
        specialty: value.specialty,
        occupationAreas: occupationAreasMapper(value.occupationAreas),
        originalData: null,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}
input.form-control {
  text-align: right;
  border: 1px solid transparent;
}
select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.icon-remove {
  margin-right: 0.8rem;
  margin-left: 0.2rem;
  width: 1.8rem;
  height: 1.8rem;
}
.icon-heart-plus {
  width: 1.8rem;
  height: 1.8rem;
}

select.text-align-left {
  text-align: left;
  padding-left: 0px;
}
.check-apadding {
  padding-top: 0.5rem;
}
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}
.alter-form div.card {
  padding: 0rem;
}
</style>
